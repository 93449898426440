import { GatsbySeo } from 'gatsby-plugin-next-seo'

const NotFoundPage = () => (
  <>
    <GatsbySeo title="404" />
    <section>404</section>
  </>
)

export default NotFoundPage
